import {createStyles, withStyles} from '@material-ui/core/styles';
import {IconButton, withWidth, MenuItem, Typography} from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import UserMenu from 'components/UserMenu';
import useDevelop from 'hooks/useDevelop';
import {toggleSidebar as toggleSidebarAction} from 'ra-core';
import React, {useCallback, useState} from 'react';
import {
	getResources,
	MenuItemLink,
	useDataProvider,
	useTranslate,
} from 'react-admin';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import {getUserInfo} from 'utils/Data';
import {loadObject, saveObject} from 'utils/Util';
import UserProfile from '../components/UserProfile';
import {
	Organization,
	Overview,
	ToolsAndSupport,
	Support,
	InDevelopment,
} from '../assets/MenuIcon';
import TsIcon from 'assets/TalespinIconLight.svg';
import {useLocation} from 'react-router-dom';
import {LOG_OUT} from 'services/customActions';
import analytics from "analytics/analytics";

const myStyles = theme =>
	createStyles({
		active: {
			fontWeight: theme.palette.fontWeight.sideMenuActiveItem,
			backgroundColor: theme.palette.background.sideMenuActiveItem,
			color: theme.palette.color.sideMenuActiveItem,
			'& svg': {
				color: theme.palette.color.sideMenuActiveItem + '!important',
			},
			borderLeftWidth: '3px',
			borderLeftStyle: 'solid',
			borderLeftColor: theme.palette.borderColor.sideMenuActiveItem,
			'& label': {
				borderBottomStyle: 'none !important',
				color: theme.palette.color.sideMenuActiveItem,
			},
		},
		label: {
			color: 'white',
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
			flex: '0 0 auto',
			transition: 'min-width 0.5s, max-width 0.5s',
			backgroundColor: theme.palette.background.sideMenu,
		},
		open: {
			width: '220px',
		},
		closed: {
			minWidth: '55px',
			maxWidth: '55px',
		},
		sideMenuToggle: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			marginTop: '4px',
			alignItems: 'center',
			cursor: 'pointer',
			'& button': {
				color: theme.palette.color.sideMenuToggleIcon,
				marginLeft: '0.2em',
			},
			'& img': {
				maxWidth: '145px',
				marginLeft: '0.3em',
			},
		},
		sideMenuItems: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflowY: 'auto',
			borderTop: `2px solid ${theme.palette.borderColor.sideMenu}`,
			borderBottom: `2px solid ${theme.palette.borderColor.sideMenu}`,
			paddingTop: '10px',

			'& a': {
				flex: '0 0 auto',
				display: 'flex',
				flexDirection: 'row',
				overflow: 'hidden',
				'& svg': {
					color: theme.palette.color.sideMenuItemIcon,
				},
				'& label': {
					width: '50%',
					paddingTop: '4px',
					paddingBottom: '10px',
					borderBottomWidth: '2px',
				},
			},
		},
		sideMenuFooter: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.sideMenuFooter,
		},
		successIcon: {
			color: 'green',
		},
		logoSuffix: {
			color: '#FFFFFF',
			display: 'flex',
			justifyContent: 'flex-end',
			fontFamily: 'Archivo Bold',
			textTransform: 'uppercase',
		},
	});

// Since we are apparently mixing icon components, the Resource links don;t always play nice
// with all of the properties. This functional component merely wraps the icon and prevents
// the props from being passed to the actual icon.
const IconWrapper = props => {
	const {children} = props;

	return {...children};
};

const SideMenu = props => {
	const [state, setState] = useState({
		firstLoad: true,
		isProfileOpen: false,
		loggingOut: false,
	});

	const [isProfileOpen, setIsProfileOpen] = useState(false);

	const {isDebug} = useDevelop();

	const location = useLocation();

	const dataProvider = useDataProvider();

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const fetchData = useCallback(async () => {
		try {
			// const {data: runwayOrgConfig} = await dataProvider.getOne(
			// 	'runwayOrgConfig'
			// );
			// setState(state => ({
			// 	...state,
			// 	runwayOrgConfig: runwayOrgConfig,
			// }));
			// saveObject('runwayOrgConfig', runwayOrgConfig);

			const {data: OrgStats} = await dataProvider.getOne('OrgStats');
			saveObject('OrgStats', OrgStats);
			setState(state => ({
				...state,
				OrgStats: OrgStats,
			}));
		} catch {}
	}, [dataProvider]);

	if (state.firstLoad && getUserInfo().isAuthenticated) {
		fetchData();
		setState({...state, firstLoad: false});
	}

	const translate = useTranslate();

	const {classes, open, resources, toggleSidebar} = props;

	var projectCache = JSON.parse(sessionStorage.getItem('project'));

	var passportUrl = `https://${
		projectCache?.id ? projectCache?.id : projectCache?.config.orgId
	}.passport.talespin.io/`;

	const enableToolsAndReporting =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.enableToolsAndReporting
			: 'true';

	//global property to enable In Development page
	const enableInDevelopment =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.enableInDevelopment
			: 'false';

	const showChildSubLicense =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.showChildSubLicense
			: 'false';
			
	if (!getUserInfo()) {
		return <div></div>;
	}

	if (getUserInfo().isUser) {
		return <div></div>;
	}

	if (window.location.hash.includes('error')) {
		return <div></div>;
	}

	const logOut = async () => {
		setState({...state, loggingOut: true});

		try {
			await dataProvider(LOG_OUT, 'LOGOUT', {}).then(() =>{
				console.log('Logged out the user')
				    // Analytics: 'User Logout' event tracking.
				analytics.track('User Logout', {
					category: 'Logout',
					name: 'User Logout',
				});
		        analytics.reset();
			});
		} catch (error) {
			console.error(error);
			setState({...state, loggingOut: false});
			return;
		}

		//clear session storage
		const projectCache = JSON.parse(sessionStorage.getItem('project'));
		const project = projectCache ? projectCache.id : '';
		sessionStorage.removeItem(project + '-token');
		sessionStorage.removeItem(project + '-packetId');
		sessionStorage.removeItem(project + '-user');
		//redirect to login
		window.location = '#/login';
	};

	return (
		<div
			className={classnames({
				[classes.root]: true,
				[classes.open]: open,
				[classes.closed]: !open,
			})}
		>
			<div className={classes.sideMenuToggle}>
				<IconButton aria-label='open drawer' onClick={toggleSidebar}>
					<MenuIcon />
				</IconButton>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<img src={TsIcon} alt='logo' />
					<Typography className={classes.logoSuffix}>
						{runwayOrgConfig?.config?.displayOrgText}
					</Typography>
				</div>
			</div>

			<div className={classes.sideMenuItems}>
				{(getUserInfo().isAdmin || getUserInfo().isSuper) &&
				(showChildSubLicense === 'true') ? (
					<MenuItemLink
						to='/subLicenses'
						exact
						isActive={() => ['/subLicenses'].includes(location.pathname)}
						primaryText={<label>Child Licenses</label>}
						leftIcon={
							<IconWrapper>{React.createElement(Organization)}</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
				{getUserInfo().isAdmin || getUserInfo().isSuper ? (
					<MenuItemLink
						to='/'
						exact
						isActive={() =>
							['/', '/manageLicenses'].includes(location.pathname)
						}
						primaryText={<label>Organization</label>}
						leftIcon={
							<IconWrapper>{React.createElement(Organization)}</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}

				{getUserInfo().isAdmin || getUserInfo().isSuper ? (
					<MenuItemLink
						to='/overview'
						exact
						primaryText={<label>Overview</label>}
						leftIcon={
							<IconWrapper>{React.createElement(Overview)}</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}

				{resources.map(resource => {
					if (!resource.hasList) {
						return null;
					}

					const displayText = translate(`resources.${resource.name}.name`);

					if (
						!getUserInfo().isAdmin &&
						!getUserInfo().isSuper &&
						resource.name === 'trainings'
					) {
						return null;
					}
					return (
						<MenuItemLink
							key={resource.name}
							to={{
								pathname: `/${resource.name}`,
								// keeps the selected skills when user applies skill filter and naviagate to trainings/trainingId/show and navigate back to /trainings
								state:
									resource.name === 'trainings' &&
									location.state?.selectedSkills
										? location.state
										: undefined,
							}}
							primaryText={
								<label>
									{resource.options && resource.options.label
										? resource.options.label
										: displayText}
								</label>
							}
							leftIcon={
								<IconWrapper>{React.createElement(resource.icon)}</IconWrapper>
							}
							// onClick={() => clearRequestQueue()}
							classes={{root: classes.label, active: classes.active}}
						/>
					);
				})}

				{(getUserInfo().isAdmin || getUserInfo().isSuper) &&
					enableInDevelopment === 'true' && (
						<MenuItemLink
							to='/inDevelopment'
							primaryText={<label>{`In Development`}</label>}
							leftIcon={
								<IconWrapper>
									<InDevelopment />
								</IconWrapper>
							}
							// onClick={() => clearRequestQueue()}
							classes={{root: classes.label, active: classes.active}}
						/>
					)}

				{(getUserInfo().isAdmin || getUserInfo().isSuper) &&
				enableToolsAndReporting === 'true' ? (
					<MenuItemLink
						to='/toolsReports'
						exact
						primaryText={<label>{`Tools & Reports`}</label>}
						leftIcon={
							<IconWrapper>
								<ToolsAndSupport />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
				{getUserInfo().isAuthenticated ? (
					<MenuItemLink
						to='/support'
						exact
						isActive={() => ['/support'].includes(location.pathname)}
						primaryText={<label>Support</label>}
						leftIcon={
							<IconWrapper>
								<Support />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
				{isDebug && (getUserInfo().isAdmin || getUserInfo().isSuper) ? (
					<MenuItemLink
						to='/debug'
						exact
						primaryText={<label>{`Debug`}</label>}
						leftIcon={
							<IconWrapper>
								<BugReportIcon />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
				{isDebug && (getUserInfo().isAdmin || getUserInfo().isSuper) ? (
					<MenuItemLink
						to='/debug-css'
						exact
						primaryText={<label>{`CSS`}</label>}
						leftIcon={
							<IconWrapper>
								<BugReportIcon />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
				{isDebug &&
				(getUserInfo().isAdmin || getUserInfo().isSuper) &&
				enableToolsAndReporting ? (
					<MenuItemLink
						to='/debug-materialui'
						exact
						primaryText={<label>{`Material UI`}</label>}
						leftIcon={
							<IconWrapper>
								<BugReportIcon />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}

				{isDebug &&
				(getUserInfo().isAdmin || getUserInfo().isSuper) &&
				enableToolsAndReporting ? (
					<MenuItemLink
						to='/debug-artaudit'
						exact
						primaryText={<label>{`Art Audit`}</label>}
						leftIcon={
							<IconWrapper>
								<BugReportIcon />
							</IconWrapper>
						}
						// onClick={() => clearRequestQueue()}
						classes={{root: classes.label, active: classes.active}}
					/>
				) : null}
			</div>

			<div className={classes.sideMenuFooter}>
				<UserMenu
					role={getUserInfo().role}
					name={getUserInfo().name}
					bordered={open}
				>
					<MenuItem id='profile' onClick={() => setIsProfileOpen(true)}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							Profile
						</div>
					</MenuItem>

					{passportUrl && (
						<MenuItem
							id='open-passport-app'
							onClick={() => window.open(passportUrl)}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								Open Passport App
							</div>
						</MenuItem>
					)}

					<MenuItem
						id='logout'
						disabled={state.loggingOut}
						onClick={() => logOut()}
					>
						<div>{state.loggingOut ? 'Logging Out...' : 'Log Out'}</div>
					</MenuItem>
				</UserMenu>
				<p
					style={{
						fontSize: '12px',
						color: 'White',
						marginTop: '0px',
						textAlign: 'center',
					}}
				>
					V {process.env.REACT_APP_VERSION}
				</p>
				{/* <AppVersion icon={open ? tsLogo : tsIcon} /> */}
			</div>
			<UserProfile isOpen={isProfileOpen} setIsOpen={setIsProfileOpen} />
		</div>
	);
};

const enhance = compose(
	connect(
		state => ({
			resources: getResources(state),
			open: state.admin.ui.sidebarOpen,
		}),
		{
			toggleSidebar: toggleSidebarAction,
		}
	),
	withRouter,
	withStyles(myStyles),
	withWidth()
);

export default enhance(SideMenu);
