import {
	createTheme,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';

import TSTheme from 'components/Theme';
import compose from 'recompose/compose';
import SystemMessageAlert from 'components/Banners/SystemMessageAlert';
import {useLocation} from 'react-router-dom';

import {Authenticator} from 'talespin-bc-authenticator';

import MemoCircleLoading from 'assets/CircleLoading';
import MemoLessThan from 'assets/LessThan';
import decodeJwt from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import {getOrgId, saveObject} from 'utils/Util';
import { Link } from "@material-ui/core";
import analytics from "analytics/analytics";

var defaultProxy = 'https://restportalproxy.internalg.braincloudservers.com';
var envProxy = process.env.REACT_APP_REST_PROXY;
var targetProxy = envProxy ? envProxy : defaultProxy;

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		alignItems: 'center',
		justifyContent: 'flex-start',
		background: 'url(./u2.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	button: {
		width: '100%',
	},
	card: {
		height: '100%',
	},
	spacer: {flex: 1, display: 'block'},
	flex: {display: 'flex', flexDirection: 'column', height: '100%'},
	cardHeader: {
		alignSelf: 'center',
		textAlign: 'center',
		background: theme.palette.background.contained,
	},
	cardFooter: {
		alignSelf: 'center',
		textAlign: 'center',
		background: theme.palette.background.contained,
		width: '100%',
		paddingTop: '14px',
		paddingBottom: '14px',
	},
	icon: {
		left: '-30px',
		width: '18px',
		marginRight: '-18px',
		position: 'relative',
	},
	logo: {
		width: '234px',
		margin: 'auto',
	},
	root: {
		background:
			'transparent radial-gradient(closest-side at 50% 0%, #383C51 0%, #292C3C 22%, #292C3C 100%) 0% 0% no-repeat padding-box',
	},
	loginLayoutMain: {
		'& div:nth-child(1)': {
			'& div:nth-child(1)': {
				'& div:nth-child(1)': {
					'& div:nth-child(2)': {
						'& div:nth-child(2)': {
							// color: '#000000 !important',
							'& a': {
								color: '#326dec',
							},
						},
					},
				},
			},
		},
	},
});

const LoginPage = props => {
	const {classes} = props;

	const project = getOrgId();

	const data = JSON.parse(sessionStorage.getItem('project'))?.config;

	const location = useLocation();

	const [showSystemMessage, setShowSystemMessage] = useState();
	const [creds, setCreds] = useState(null);
	// const [dataUri, setDataUri] = useState(sessionStorage.getItem(getOrgId() + '-appLogo'));
	const [message, setMessage] = useState(null);

	let mainLogo = '';
	if (data && data.images) {
		const logoFromStorage = sessionStorage.getItem(getOrgId() + '-appLogo');
		if (logoFromStorage != null) {
			mainLogo = logoFromStorage;
		} else {
			mainLogo = data.images.runwayLoginLogo;
		}
	}

	useEffect(() => {
		const  projectCache = JSON.parse(sessionStorage.getItem('project'));
		localStorage.setItem(
		'userData',
		JSON.stringify({
			isSystemMessageDismissed: false,
		})
	);

		setShowSystemMessage(!projectCache?.config?.systemMessage?.afterLogin);
		if (sessionStorage.getItem('networkError') === 'true') {
			setMessage({
				messageType: 'error',
				messageTitle: 'No Connection',
				messageText: 'Please ensure you have a valid internet connection and refresh the page'
			});
			sessionStorage.removeItem('networkError');
		}
	}, []);

	useEffect(() => {
		getBase64FromUrl(mainLogo).then((val) => {
		}).catch(e => console.error(e))
	}, [])

	const getBase64FromUrl = async (url) => {
		try {
			const data = await fetch(url);
			const blob = await data.blob();
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result;
					sessionStorage.setItem(getOrgId() + '-appLogo', base64data);
					resolve(base64data);
				}
			});
		} catch (e) {
			return new Promise((resolve) => {
				resolve(mainLogo)
			})
		}
	}

	const onDataConsent = dataConsent => {
		console.log('Data-Consent is:', dataConsent);
	};

	useEffect(() => {
        /* Track a page view */
		if (!creds) {
			analytics.reset();
		}
        analytics.page();
        if (creds) {
            const jwt = creds?.jwt;
            const jwtDecoded = jwt_decode(jwt);

            /* Analytics: identify a visitor */
            analytics.identify(`${jwtDecoded?.id}`, {
                sessionId: jwtDecoded?.sessionId,
                organizationId: getOrgId(),
				userId: jwtDecoded?.id
            });

			// Analytics: 'User Login' event tracking.
			analytics.track('User Login', {
				category: 'Login',
				name: 'User Login',
				sessionId: jwtDecoded?.sessionId,
			});
        }
    }, [creds]);

	const onLogin = async creds => {
		setCreds(creds);
		const decodedToken = decodeJwt(creds.jwt);

		sessionStorage.setItem(project + '-token', creds.jwt);
		sessionStorage.setItem(
			project + '-packetId',
			sessionStorage.getItem('xPacketId')
		);
		sessionStorage.setItem(
			project + '-user',
			JSON.stringify({
				name: decodedToken.name,
				email: decodedToken.email,
				role: decodedToken.role,
				id: decodedToken.id,
				timeZone: creds?.userData?.timeZone
			})
		);
		sessionStorage.setItem(
			project + '-summaryFriendData',
			JSON.stringify({...creds.userData})
		);
		sessionStorage.setItem('hasAccepetedTermsOfService', creds.tosAccepted);
		saveObject('runwayOrgConfig', creds.orgConfig.data);
		if (location.state?.from?.pathname) {
			window.location = `#${location.state.from.pathname}`;
		} else {
			window.location = '#/';
		}
	};

	const onLogout = () => {
		sessionStorage.removeItem(project + '-token');
		sessionStorage.removeItem(project + '-packetId');
		sessionStorage.removeItem(project + '-user');
		window.location = '#/login';
	};

	return (
		<div style={{ 
			minHeight: '100vh',
			display: 'flex',
			justifyContent: 'center',
			position: 'relative'
		}}>
			{showSystemMessage && <div style={{ width: '100%', position: 'absolute' }}><SystemMessageAlert /></div>}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				className={classes.loginLayoutMain}
			>
				<Authenticator
					appName={'runway'}
					serverBasePath={targetProxy}
					orgId={getOrgId()}
					branding={getBrandingObj(data?.images?.runwayLoginLogo)}
					dataConsentTriggerElementId={'data-consent-trigger'}
					onDataConsent={dataConsent => onDataConsent(dataConsent)}
					postAuthCheck={(creds) => {
                        if (creds?.userData?.role?.indexOf('user') >= 0) {
                            return 'Access denied. Contact your administrator to raise your permissions.';
                        }
                    }}
					onLogin={newCreds => onLogin(newCreds)}
					onLogout={() => onLogout()}
					enablePasswordReset={true}
					displayMessage={message}
				/>

           {!creds && (
                <div
                    className="global-pp-tos-dc-links"
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        textAlign: 'center',
                        color: '#326dec',
						marginBottom: '-15px'
                    }}
                >
                    {/* <Link
                        style={{
                            textDecoration: 'underline',
                            letterSpacing: '0px',
                            font: 'normal normal normal 12px/14px "Archivo Regular"',
                            color: '#326dec',
                            cursor: 'pointer',
                            margin: '8px',
                        }}
                        href={'https://www.talespin.com/privacy-policy'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </Link>
                    {' | '}
                    <Link
                        style={{
                            textDecoration: 'underline',
                            letterSpacing: '0px',
                            font: 'normal normal normal 12px/14px "Archivo Regular"',
                            fontSize: '12px',
                            fontFamily: 'Archivo Regular',
                            color: '#326dec',
                            cursor: 'pointer',
                            margin: '8px',
                        }}
                        href={'https://www.talespin.com/terms-of-use'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Use
                    </Link> */}
                    {data && data.dataConsent && data.dataConsent?.dashboard && <>
						<Link
							id="data-consent-trigger"
							style={{
								textDecoration: 'underline',
								letterSpacing: '0px',
								font: 'normal normal normal 12px/14px "Archivo Regular"',
								color: '#326dec',
								cursor: 'pointer',
								margin: '8px',
							}}
						>
							Change Cookie Preferences
						</Link>
					</>}
                </div>
            )}

				{/* {!creds && (
					<div style={{marginTop: '1rem'}}>
						<button
							id='data-consent-trigger'
							style={{
								backgroundColor: 'transparent',
								border: 'none',
								cursor: 'pointer',
								textDecoration: 'underline',
								margin: '0 auto',
								padding: '0',
								textAlign: 'center',
								display: 'block',
								color: '#346dec',
								font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
							}}
						>
							Change Cookie Preferences
						</button>
					</div>
				)} */}
			</div>
		</div>
	);
};

const enhance = compose(withStyles(styles));

const EnhancedLogin = enhance(LoginPage);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme

const LoginWithTheme = props => {
	return (
		<MuiThemeProvider theme={createTheme(TSTheme)}>
			<EnhancedLogin {...props} />
		</MuiThemeProvider>
	);
};

export default LoginWithTheme;

const getBrandingObj = (appMainLogoUrl) => {
	let imageUrl = appMainLogoUrl;

	const imageDataUri = sessionStorage.getItem(getOrgId() + '-appLogo');

	if (imageDataUri != null) imageUrl = imageDataUri;
	return {
		main: {
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			backgroundColor: 'lightgrey',
			color: 'black',
			borderColor: 'black',
			width: '27.5rem',
			margin: '0 auto !important',
			padding: '32px 54.4px',
			background: '#FFFFFF 0% 0% no-repeat padding-box !important',
		},
		authHeader: {
		    headerIcon: <img style={{width: '234px', margin: 'auto'}} src={imageUrl} alt='logo' />,
			headingText: 'Welcome to Talespin Platform',
			general: {
				color: '#292C3C',
				fontFamily: 'Montserrat Bold',
				letterSpacing: '0.36px',
				fontSize: '18px',
				lineHeight: '22px',
				fontWeight: 500,
				textShadow: '0rem 0rem .625rem #0000001A',
				marginTop: '.75rem',
				marginBottom: '.75rem',
			},
			subtitle: {
				fontFamily: 'Montserrat Medium',
				letterSpacing: '0.36px',
				fontSize: '15px',
				lineHeight: '35px',
				fontWeight: 500,
				color: '#696E84',
				textAlign: 'center',
			},
		},
		column: {
			marginTop: '5px',
			marginBottom: '5px',
		},
		form: {
			margin: '0.5rem auto',
			width: '100%',
			input: {
				height: '2.125rem',
				outline: 'none',
				padding: '8.5px .875rem',
				backgroundColor: '#ffffff',
				color: '#696E84',
				borderColor: '#adc2cc',
				borderRadius: '0.3125rem',
				borderWidth: '.0625rem',
				fontFamily: 'Montserrat Medium',
				fontSize: '0.8125rem',
				lineHeight: '16px',
				letterSpacing: '.6496px',
				boxShadow: '0px 2px 3px #0000001a',
				borderStyle: 'solid',
				hover: {
					borderColor: '#adc2cc',
					color: '#696E84',
					backgroundColor: '#ffffff',
				},
				focused: {
					color: '#696E84',
					borderColor: '#adc2cc',
					borderWidth: '2px',
					height: '2.125rem',
					padding: '8.5px 13px', // Reduce the left and right padding to factor in the borderWidth increase to keep the text fixed.
				},
				webkitAutofill: {
					textFillColor: '#696E84',
					boxShadow: undefined,
				},
				infoText: {
					margin: 0,
					textAlign: 'center',
					color: '#696E84',
					fontFamily: 'Montserrat Medium',
					fontSize: '.8125rem',
					lineHeight: '1.125rem',
					letterSpacing: '0.65px',
					fontWeight: 500,
				},
				button: {
					primary: {
						boxSizing: 'border-box',
						margin: '1.3rem auto 0',
						padding: '0.625rem',
						outline: '0px',
						border: '0px',
						cursor: 'pointer',
						borderRadius: '5px',
						font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
						letterSpacing: '0.0406rem',
						display: 'block',
						textTransform: 'capitalize',
						background:
							'transparent linear-gradient(276deg, #346dec 0%, #346dec 100%) 0% 0% no-repeat padding-box',
						color: '#ffffff',
						backgroundColor: '#346dec',
						hover: {
							textDecoration: 'none',
							backgroundColor: 'rgb(36, 76, 165)',
							background: 'rgb(36, 76, 165) !important',
							boxShadow:
								'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
						},
						width: '100%',
						height: '35px',
						disabled: {
							background: '#cccccc',
							color: '#666666',
							cursor: 'inherit',
						},
					},
					secondary: {
						boxSizing: 'border-box',
						margin: '1.3rem auto 0',
						padding: '0.625rem',
						outline: '0px',
						border: '0px',
						cursor: 'pointer',
						borderRadius: '5px',
						font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
						letterSpacing: '0.0406rem',
						display: 'block',
						textTransform: 'capitalize',
						background: 'transparent #9e9e9e 0% 0% no-repeat padding-box',
						color: 'white',
						backgroundColor: '#9e9e9e',
						hover: {
							textDecoration: 'none',
							backgroundColor: '#908e8e',
						},
						width: '100%',
						height: '35px',
						disabled: {
							background: '#cccccc',
							color: '#666666',
							cursor: 'inherit',
						},
					},
				},
				buttonLink: {
					backgroundColor: '#ffffff',
					border: 'none',
					cursor: 'pointer',
					textDecoration: 'underline',
					margin: '1rem auto 0',
					padding: '0',
					textAlign: 'center',
					display: 'block',
					color: '#346dec',
					font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
				},
				helperText: {
					fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
					fontWeight: 400,
					fontSize: '0.75rem',
					lineHeight: 1.66,
					letterSpacing: '0.03333em',
					textAlign: 'left',
					margin: '4px 14px 0px',
					color: '#ed4343',
					minHeight: '.7rem',
				},
				disabled: {
					backgroundColor: 'inherit',
					color: 'inherit',
					borderColor: 'inherit',
				},
				error: {
					backgroundColor: 'inherit',
					color: 'inherit',
					borderColor: 'inherit',
				},
			},
		},
		footer: {
			marginTop: '.5rem',
		},
		loadingIcon: <MemoCircleLoading fontSize={50} />,
		copyright: `© ${new Date().getFullYear()} Talespin Reality Labs, Inc. ${
			process.env.REACT_APP_VERSION
		}`,
		messageBox: {
			error: {
				backgroundColor: '#ED4343',
			},
			info: {
				backgroundColor: '#0288d1',
			},
			success: {
				backgroundColor: '#388e3c',
			},
			warning: {
				backgroundColor: '#f57c00',
			},
			color: '#ffffff',
			fontSize: '13px',
			fontHeight: '18px',
			fontWeight: 500,
			fontFamily: 'Archivo Regular',
			letterSpacing: '.0406rem',
			borderRadius: '5px',
			lineHeight: 1.43,
		},
		tos: {
			mainLogoUrl: imageUrl,
			mainLogoDisplayWidth: '234',
			backLinkIcon: <MemoLessThan fontSize={50} fill='#326dec' />,
			dialogTitle: {
				color: '#000000',
				fontFamily: 'Archivo Semi Bold',
				letterSpacing: '0.68px',
				fontSize: '1.25rem',
				lineHeight: 1,
				fontWeight: 500,
				textShadow: '0rem 0rem .625rem #0000001A',
				margin: '.5rem',
			},
			version: {
				fontSize: '1rem',
			},
			checkboxText: {
				color: '#326dec',
				fontFamily: 'Archivo Semi Bold',
				fontWeight: 400,
				fontSize: '.875rem',
				lineHeight: '20px',
			},
			infoText: {
				margin: 0,
				textAlign: 'center',
				color: '#000000',
				fontFamily: 'Montserrat Regular',
				fontSize: '14px',
				lineHeight: '1.2rem',
				letterSpacing: '0.65px',
				fontWeight: 600,
			},
			termsText: {
				fontFamily: 'Archivo Regular',
				letterSpacing: '0.65px',
				fontSize: '14px',
				lineHeight: '1.4rem',
				fontWeight: 400,
				color: '#000000 !important',
			},
			termsLink: {
				color: '#ffffff',
			},
		},
		dataConsentDialog: {
			dialogMask: {
				background: '#000000 0% 0% no-repeat padding-box',
				opacity: '0.3',
				// height: '100vh !important'
			},
			dialog: {
				width: '450px',
				height: 'fit-content',
				padding: '30px',
				background:
					'transparent radial-gradient(closest-side at 50% 0%, #ffffff 0%, #ffffff 22%, #ffffff 100%) 0% 0% no-repeat padding-box',
				borderRadius: '0',
				boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
			},
			dialogTitle: {
				fontFamily: 'Archivo Bold',
				letterSpacing: 0,
				fontSize: '18px',
				lineHeight: '20px',
				fontWeight: 600,
				textShadow: '0rem 0rem .625rem #0000001A',
				margin: '0 0 18px',
				padding: '0 0 10px',
				borderBottom: '1px solid #E0E0E0',
				color: '#000000',
			},
			infoTextHeading: {
				margin: '11px 0',
				textAlign: 'left',
				color: '#000000',
				fontFamily: 'Montserrat Regular',
				fontSize: '14px',
				lineHeight: '18px',
				letterSpacing: '0',
				fontWeight: 600,
			},
			infoText: {
				margin: '11px 0',
				textAlign: 'left',
				color: '#000000',
				fontFamily: 'Montserrat Regular',
				fontSize: '13px',
				lineHeight: '18px',
				letterSpacing: '0',
				fontWeight: 'normal',
			},
			infoTextLink: {
				color: '#326dec',
			},
			privacyPolicyLink: {
				url: 'https://www.talespin.com/privacy-policy',
			},
		},
	};
};
