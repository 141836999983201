import React, { useEffect, useState } from 'react';
import { ScreenTitle, EmbeddedShow } from 'components';
import MUIButton from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { getUserInfo } from 'utils/Data';
import { GET_USER_CPD_URLS } from "services/customActions";
import { fetchEnd, fetchStart, useDataProvider } from "react-admin";
import { useDispatch } from "react-redux";
import { getOrgId } from "utils/Util";
import AccessAppLink from "./AccessAppLink";
import OpenNewTabIcon from 'assets/OpenNewTabIcon';
import EmailIcon from 'assets/EmailIcon';

const myStyles = theme =>
    createStyles({
        divider: {
            border: 0,
            borderBottom: '1px solid',
            color: theme.palette.borderColor.divider,
            width: '100%',
            height: '1px',
        },
    });

const Support = props => {
    const { classes } = props;

    const runwayOrgConfig = JSON.parse(sessionStorage.getItem('runwayOrgConfig'));
    const { planContactEmail, facilitatorEmail, knowledgeBaseUrl, systemStatusUrl } = runwayOrgConfig.config;
    const tsaStreamingEnabled = runwayOrgConfig?.config?.tsaStreamingEnabled;
	const tsaWebGLEnabled = runwayOrgConfig?.config?.tsaWebGLEnabled;
    const vrAppTSAUrl = runwayOrgConfig?.config?.vrAppTSAUrl;
    const enableCPDPublication = runwayOrgConfig?.config?.enableCPDPublication === 'true';
    const accessCpdInBrowserPanel = enableCPDPublication && getUserInfo().trainingModuleEditor;

    const dataProvider = useDataProvider();

    const [streamingPlayerUrl, setStreamingPlayerUrl] = useState(null);

    const [cpdWebServiceUrl, setCpdWebServiceUrl] = useState();

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (tsaStreamingEnabled || accessCpdInBrowserPanel) {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        dispatch(fetchStart());
        setLoading(true);
        await dataProvider(GET_USER_CPD_URLS, 'getUserURLs', {
            orgId: getOrgId()
        }).then(res => {
            if (res && res.streamingPlayerRoot) {
                setStreamingPlayerUrl(res.streamingPlayerRoot);
            }
            if (res && res.cpdWebService) {
                setCpdWebServiceUrl(res.cpdWebService);
            }
        }).finally(() => {
            dispatch(fetchEnd());
            setLoading(false);
        });
    }

    if (!getUserInfo().isAuthenticated) {
        return <Redirect to='login' />;
    }

    return (
        <div>
            <ScreenTitle title='Support' />
            <EmbeddedShow title='Knowledge Base' height={'auto'}>
                <div className={classes.info} style={{ lineHeight: 1.5}}>
                    Learn more about the Talespin Platform through our Knowledge Base website.
                </div>
                <hr className={classes.divider} />
                <MUIButton
                    variant={'contained'}
                    color={'primary'}
                    style={{height: '32px', width: '200px'}}
                    size='small'
                    startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
                    onClick={() => window.open(knowledgeBaseUrl)}
                >
                    Open Knowledge Base
                </MUIButton>
            </EmbeddedShow>

            {accessCpdInBrowserPanel && <div style={{ marginTop: '24px' }}>
                <AccessAppLink
                    loading={loading}
                    title={'Access CoPilot Designer in your Browser'}
                    body={
                        <>
                            You have access to create training modules with the CoPilot Designer authoring tool.
                        </>
                    }
                    link={cpdWebServiceUrl}
                    openLinkButtonTitle={'Open CoPilot Designer'}
                    app={'cpdWeb'}
                />
            </div>}

            {(tsaStreamingEnabled || tsaWebGLEnabled) && (
                <div style={{ marginTop: '24px' }}>
                    <AccessAppLink
                        loading={loading}
                        title={'Access the Talespin App in your Browser'}
                        body={
                            <>
                                Your organization can access your licensed modules that are compatible with the Talespin App via our browser-based version of the Talespin App.
                            </>
                        }
                        link={streamingPlayerUrl}
                        openLinkButtonTitle={'Open Talespin App'}
                        app={'streamngPlayer'}
                    />
                </div>
            )}

            <div style={{ marginTop: '24px' }}>
            <EmbeddedShow title='Experience the Talespin App in VR' height={'auto'}>
                    {<>
                        <div className={classes.info} style={{ lineHeight: 1.5 }}>
                        Your organization can access your licensed modules that are compatible with the Talespin App via the VR version of the Talespin App, available on the Meta Quest Store. Be sure to follow your company’s policies when it comes to installing applications on your VR device. Please check with your IT department if you are unsure on how to install this application.
                            <br/>
                            <span style={{ fontWeight: "bold"}}>NOTE:</span>
                            <span style={{ fontStyle: "italic"}}> When prompted in the VR app, learners from your organization should enter the org ID: </span>
                            <span style={{ fontWeight: "bold"}}>{getOrgId()}</span>
                        </div>
                        <hr className={classes.divider} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <MUIButton
                                variant={'contained'}
                                color={'primary'}
                                style={{ height: '32px', width: '344px' }}
                                size='small'
                                startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
                                onClick={() => window.open(vrAppTSAUrl, '_blank')}
                            >
                                View Talespin App on the Meta Quest Store
                            </MUIButton>
                        </div>
                    </>}
                </EmbeddedShow>
            </div>

            <div style={{ marginTop: '24px' }}>
                <EmbeddedShow title='Contact Us' height={'auto'}>
                    <div className={classes.info}>
                        Have a question? Send us an email.
                    </div>
                    <hr className={classes.divider} />
                    <MUIButton
                        variant={'contained'}
                        color={'primary'}
                        style={{ height: '32px', width: '160px' }}
                        size='small'
                        onClick={() =>
                            (window.location.href = 'mailto:' + (facilitatorEmail ? facilitatorEmail : planContactEmail))
                        }
                        startIcon={<EmailIcon style={{ fontSize: '18px' }}/>}
                    >
                        Email Us
                    </MUIButton>
                </EmbeddedShow>
            </div>

            <div style={{ marginTop: '24px' }}>
                <EmbeddedShow title='Platform Status' height={'auto'}>
                    <div className={classes.info}>
                        Track our platform status anytime.
                    </div>
                    <hr className={classes.divider} />
                    <MUIButton
                        variant={'contained'}
                        color={'primary'}
                        style={{height: '32px', width: '200px'}}
                        size='small'
                        onClick={() => window.open(systemStatusUrl)}
                        startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
                    >
                        Check Platform Status
                    </MUIButton>
                </EmbeddedShow>
            </div>
        </div>
    )
}

export default withStyles(myStyles)(Support);
